/*
 * @Description: 商城配置数据
 * @Autor: zhangzhang
 * @Date: 2021-09-28 15:05:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-15 12:56:32
 */

export const mallTypeList = [
  {
    type: 'mall',
    logo: '@/assets/image/title.gif',
    title: '我创建的项目',
    subTitle: ' myProject',
  },
  {
    type: 'model',
    logo: '@/assets/image/m.gif',
    title: '项目模板',
    subTitle: ' projectModel',
  },
];

export const mallIndustryList = [
  { label: '医疗', value: 'medicalCare' },
  { label: '社交', value: 'socialContact' },
  { label: '教育', value: 'educate' },
  { label: '电商', value: 'eCommerce' },
  { label: '日用百货', value: 'department' },
  { label: '服饰饰品', value: 'clothes' },
  { label: '休闲零食', value: 'snacks' },
  { label: '数码家电', value: 'digital' },
  { label: '教育培训', value: 'education' },
  { label: '酒店旅游', value: 'hotel' },
  { label: '其他', value: 'other' },
];
