<!--
 * @Description: 保存
 * @Autor: zhangzhang
 * @Date: 2021-09-27 16:53:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-03 20:12:21
-->
<template>
  <el-dialog :visible.sync="show" width="40%" top="300px">
    <div class="flex-column col-center">
      <!-- form -->
      <el-form :model="form" ref="form" label-width="80px" style="width: 300px">
        <el-form-item label="名称" prop="name" verify>
          <el-input v-model="form.name" placeholder="请输入名称" clearable></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="name" verify class="form-item-none">
          <el-radio v-model="form.type" label="mall" v-if="type !== 'template'">我的项目</el-radio>
          <el-radio v-if="modelShow" v-model="form.type" label="model">项目模板</el-radio>
        </el-form-item>

        <el-form-item label="行业" prop="name" verify>
          <el-select v-model="form.industry" placeholder="请选择行业">
            <el-option v-for="item in mallIndustryList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间" prop="useTimeLength" verify v-if="type !== 'template' && createType === 'admin'">
          <el-input v-model="form.useTimeLength" placeholder="请输入天数" clearable>
            <template #append>
              <span>天</span>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button type="primary" round style="width: 140px" @click="submit">创建</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'
import { mapGetters, mapMutations } from 'vuex'
import { rojectModel } from '@/config/project'
import { mallIndustryList, mallTypeList } from '@/config/mall'

export default {
  name: 'CreateDialog',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mallIndustryList,
      show: false,
      modelShow: false,
      form: {
        type: 'mall'
      },
      createType: '',
      project: localStorage.getItem('mall-project')
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    if (this.type == 'template') {
      this.form.type = 'model'
    }
  },
  methods: {
    ...mapMutations(['setProject']),

    open(value) {
      this.show = true
      this.createType = value
      if (this.createType === 'admin') {
        this.modelShow = true
      }
    },

    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.create()
        }
      })
    },

    /**
     * 创建
     */
    async create() {
      if (this.createType === 'user') {
        this.form.useTimeLength = 3
        this.form.state = 1 //用户试用
      }
      if (this.form.type === 'model') {
        this.form.useTimeLength = 99999
      }

      this.form.useTimeLength = Number(this.form.useTimeLength)
      let map = new Map()
      mallTypeList.map(item => map.set(item.type, item.logo))

      let project = {
        ...this.$cloneDeep(rojectModel),
        ...this.form,
        ...{ logo: map.get(this.form.type), createType: this.createType }
      }
      console.log(project, 'project')

      const operation = this.form.type === 'model' ? 'addModel' : 'addProject'
      const res = await http.POST('/jw-admin', {
        module: 'project',
        operation: operation,
        ...project
      })

      if (res.status == '1') {
        project._id = res.id
        this.setProject(project)
        this.$router.push('/mall/home/homeData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  border: 0;
  margin-bottom: 5px;
}

::v-deep .el-form-item {
  .el-form-item__label {
    text-align: left;
  }

  .el-form-item__error {
    top: 47px;
    left: -80px;
  }
}

::v-deep .el-button--primary {
  background: $color-theme !important;
}

::v-deep .el-select {
  width: 100%;

  // input {
  //   padding-left: 0;
  //   border: 0;
  // }
}

.form-item-none {
  border-color: transparent;
}
</style>
